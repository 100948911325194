<!-- RTO Bind 列表 -->
<template>
  <div 
	  :lang="$i18n.locale"
	  v-loading.fullscreen="uploadloading"
    element-loading-text=""
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)" 
		class="app-container">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
		  <!-- RTO B2C 列表 显示未处理处理批次 -->
			<el-tab-pane :label="$t('rtoB2COrderPage.tabOne')" name="first">
				<div class="listHeader">
					<i></i><span>{{$t('rtoB2COrderPage.tabOnePageTitle')}}</span>
				</div>
				<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
				  style="margin:10px 0 0 20px">
				  <el-form-item label>
				    <el-input type="textarea" v-model="dlvdAwbNosArr" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.orderNoColumn')" clearable />
				  </el-form-item>
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="handleQuery">{{$t('commonInfo.searchBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleRefresh">{{$t('commonInfo.refreshBtn')}}</el-button>
					</el-form-item>
					<!-- 2023-07-12 zpy 暂不提供批量转单功能 -->
				  <!-- <el-form-item>
						<el-tooltip class="item" effect="light" :content="$t('tipsInfo.importTips')" placement="top-start">
							<el-upload
							  class="upload-demo"
							  :action="$baseUrl + '/toborder/customer/toc/rto/import'"
							  :before-upload="beforeUpload"
							  :on-success="handlesuccessMutipleTransfer"
							  :on-error="handleError"
								:show-file-list="false"
							  :on-progress="handleProgress"
							  accept=".xls, .xlsx"
							  :headers="headers"
							  file="productFile"
							  style="margin:0 15px; display: inline-block;"
							>
							  <el-button type="primary" icon="el-icon-s-promotion">{{$t('rtoB2COrderPage.multipleTransfer')}}</el-button>
							</el-upload>
						</el-tooltip>
						
						<el-button 
						  @click="handleDownloadTransferUrl()"
						>{{$t('rtoB2COrderPage.downloadTransferTemplate')}}</el-button>
				  </el-form-item> -->
					<el-form-item>
						<el-tooltip class="item" effect="light" :content="$t('tipsInfo.selectRowTips')" placement="top-start">
							<el-button type="primary" icon="el-icon-upload" @click="handleInventorySKU('2')">{{$t('rtoB2COrderPage.multipleInventory')}}</el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="light" :content="$t('tipsInfo.selectRowTips')" placement="top-start">
							<el-button type="danger" :loading="confirmLoading" icon="el-icon-delete" @click="handleMultipleDestory">{{$t('rtoB2COrderPage.multipleDestory')}}</el-button>
						</el-tooltip>
					</el-form-item>
				</el-form>
				
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px)" 
				:row-key="getRowKey"
				ref="tableList"
				v-loading="loading" @selection-change="changeSelection" :data="dataList" border>
					<el-table-column type="selection" :reserve-selection="true" align="center" />
				  <el-table-column prop="awbNo" :label="$t('commonInfo.orderNoColumn')" align="center">
						<template slot-scope="{ row }">
							<span>{{row.awbNo}}</span>
							<p v-if="row.newAwbNo != '' && row.newAwbNo != null && row.actionTypeStatus == '2'" class="awbNoRow">(<i class="el-icon-s-promotion" style="margin: 0 4px;"></i><span>{{row.newAwbNo}}</span>)</p>
						</template>
					</el-table-column>
				  <!-- <el-table-column prop="tplAwbNo" label="Tpl AwbNo" align="center"></el-table-column> -->
				  <el-table-column prop="orderType" :label="$t('commonInfo.orderType')" align="center"></el-table-column>
				  <el-table-column prop="initTime" :label="$t('commonInfo.inventoryTime')" align="center" width="180"></el-table-column>
					<!-- <el-table-column prop="actionTypeStatusName" label="已选操作" align="center"></el-table-column> -->
					<el-table-column prop="actionTypeStatusName" :label="$t('commonInfo.statusColumn')" align="center">
						<template slot-scope="{ row }">
							<span>{{returnActionStatus(row,1)}}</span>
							<!-- <i style="margin-left: 5px;color: #0086B3;" :class="returnActionStatus(row,2)"></i> -->
						</template>
					</el-table-column>
					<el-table-column prop="" :label="$t('rtoB2COrderPage.remainingDay')" align="center">
						<template slot-scope="{ row }">
							<span v-if="row.days != null && row.days != ''">{{row.days}}</span>
							<span v-else-if="row.days == '' && (row.actionResult == null || row.actionResult == '300')">{{$t('rtoB2COrderPage.operationOverTime')}}</span>
							<!-- 存储时间超过25天提示 -->
							<el-tooltip v-if="row.actionResult == '' && getStoreDay(row) < 5" class="item" effect="dark"
								:content="$t('rtoB2COrderPage.operationTips')" placement="top-start">
								<i style="font-size: 22px; margin-left: 15px; color: #c7924c;" class="el-icon-warning"></i>
							</el-tooltip>
						</template>
					</el-table-column>	
					<el-table-column :label="$t('commonInfo.operationColumn')" align="center" width="100px">
						<template slot-scope="{ row }">
							<!-- 超时不显示操作 -->
							<div v-show="getStoreDay(row) > 0">
								<el-button v-if="row.actionResult == '' || row.actionResult == null" size="mini" type="text" icon="el-icon-document-delete" @click="handleDestory(row.awbNo)">{{$t('commonInfo.destory')}}</el-button>
								<el-button style="margin-left: 0px;" v-if="row.actionResult == '' || row.actionResult == null" size="mini" type="text" icon="el-icon-s-promotion" @click="handleTransfer(row)">{{$t('commonInfo.transfer')}}</el-button>
								<el-button v-if="row.actionResult == '' || row.actionResult == null" size="mini" type="text" icon="el-icon-upload" @click="handleInventorySKU('1',row.awbNo)">产品上架</el-button>
								<!-- <span v-if="row.actionResult != '' && row.actionResult != null">暂无</span> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="pageBottom">
				  <el-pagination
				    @size-change="handleSizeChange"
				    @current-change="handleCurrentChange"
				    :current-page="queryParams.page"
				    :page-sizes="[10, 20, 30, 40]"
				    :page-size="queryParams.limit"
				    layout="total, sizes, prev, pager, next, jumper"
				    :total="total"
				  ></el-pagination>
				</div>
			</el-tab-pane>
		  <!-- RTO B2C 已完成任务列表 -->
			<el-tab-pane :label="$t('rtoB2COrderPage.tabTwo')" name="second">
				<div class="listHeader">
					<i></i><span>{{$t('rtoB2COrderPage.tabOnePageTitle')}}</span>
				</div>
				<el-form :model="alreadyQuery" ref="alQueryForm" :inline="true" label-width="auto"
				  style="margin:10px 0 0 20px">
				  <el-form-item label>
				    <el-input type="textarea" v-model="alAwbNoArr" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.orderNoColumn')" clearable />
				  </el-form-item>
				  <el-form-item>
				    <el-button type="primary" icon="el-icon-search" @click="handleAlQuery">{{$t('commonInfo.searchBtn')}}</el-button>
				  </el-form-item>
				</el-form>
				
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="alLoading" :data="alDataList" border>
				  <el-table-column prop="awbNo" :label="$t('commonInfo.orderNoColumn')" align="center"></el-table-column>
				  <el-table-column prop="orderType" :label="$t('commonInfo.orderType')" align="center"></el-table-column>
				  <el-table-column prop="initTime" :label="$t('commonInfo.inventoryTime')" align="center" width="180"></el-table-column>
					<!-- <el-table-column prop="actionTypeStatusName" label="已选操作" align="center"></el-table-column> -->
					<el-table-column prop="actionTypeStatusName" :label="$t('commonInfo.statusColumn')" align="center">
						<template slot-scope="{ row }">
							<span>{{returnActionStatus(row,1)}}</span>
							<!-- <i style="margin-left: 5px;color: #0086B3;" :class="returnActionStatus(row,2)"></i> -->
						</template>
					</el-table-column>
				</el-table>
				<div class="pageBottom">
				  <el-pagination
				    @size-change="handleAlSizeChange"
				    @current-change="handleAlCurrentChange"
				    :current-page="alreadyQuery.page"
				    :page-sizes="[10, 20, 30, 40]"
				    :page-size="alreadyQuery.limit"
				    layout="total, sizes, prev, pager, next, jumper"
				    :total="alTotal"
				  ></el-pagination>
				</div>
			</el-tab-pane>
		</el-tabs>
		<el-dialog
		  :lang="$i18n.locale"
		  :title="$t('commonInfo.skuStockType')"
		  :visible.sync="skuPopup"
			:close-on-click-modal="false"
			:before-close="handleDialogClose"
		  width="600px">
			<div style="margin: 10px 0; height: 80px;">
				<el-tooltip class="item" effect="light" :content="$t('tipsInfo.importTips')" placement="top-start">
					<el-upload
					  class="upload-demo"
					  :action="$baseUrl + '/toborder/customer/toc/rto/addRtoMultiCheckedProduct'"
					  :before-upload="beforeUpload"
					  :on-success="handlesuccess"
						:file-list="toSkuPreFileList"
					  :on-error="handleError"
						:show-file-list="true"
						:on-preview="handlePreview"
					  :on-progress="handleProgress"
					  multiple
					  accept=".xls, .xlsx"
					  :headers="headers"
					  name="productFile"
					  style="margin-right:15px; width: 140px; display: inline-block;float: left;"
					>
					  <el-button type="primary">{{$t('commonInfo.upload')}}</el-button>
					</el-upload>
				</el-tooltip>
				<el-button style="float: left;"
				  @click="handleDownloadUrl()"
				>{{$t('commonInfo.downloadTemplate')}}</el-button>
			</div>
		  <span slot="footer" class="dialog-footer">
		    <el-button :disabled="confirmLoading" @click="handleDialogClose">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
		    <el-button :loading="confirmLoading"  type="primary" :disabled="!isSuccessData || confirmLoading" @click="confirmToSku('3')">{{confirmLoading?$t('commonInfo.dialogBtnSubmit'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
		  </span>
		</el-dialog>
		<el-dialog
		  :lang="$i18n.locale"
		  :title="$t('rtoB2COrderPage.packetTransfer')"
		  :visible.sync="transferPopup"
			:close-on-click-modal="false"
			:before-close="handleDialogClose"
		  width="800px">
			<el-row class="transferRow">
				<el-col :span="12" style="text-align: center;"><span>{{$t('rtoB2COrderPage.transferInfo')}}</span></el-col>
				<el-col :span="12" style="text-align: center;"><span>{{$t('rtoB2COrderPage.originOrderInfo')}}</span></el-col>
			</el-row>
			<el-form :model="transferInfo" ref="transferForm" :rules="rules" :inline="true" label-width="auto"
			  style="margin:10px 0 0 20px">
			  <el-form-item :label="$t('commonInfo.consignee')" prop="consignee">
			    <el-input v-model="transferInfo.consignee" style="width: 300px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.transfer') + $t('commonInfo.consignee')" />
					<el-tag class="formOrderDetail" type="info">{{transferOrderDetail.consignee}}</el-tag>
			  </el-form-item>
				<el-form-item :label="$t('commonInfo.city')" prop="destCity">
				  <el-input v-model="transferInfo.destCity" style="width: 300px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.transfer') + $t('commonInfo.city')" />
					<el-tag class="formOrderDetail" type="info">{{transferOrderDetail.destCity}}</el-tag>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.deliveryPincode')" prop="deliveryPincode">
				  <el-input v-model="transferInfo.deliveryPincode" style="width: 300px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.transfer') + $t('commonInfo.deliveryPincode')" />
					<el-tag class="formOrderDetail" type="info">{{transferOrderDetail.deliveryPincode}}</el-tag>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.payMentMode')" prop="payMentMode">
				  <el-select v-model="transferInfo.payMentMode" :placeholder="$t('tipsInfo.selectPlaceholder') + $t('commonInfo.transfer') + $t('commonInfo.payMentMode')" style="width: 300px;">
				  	<el-option v-for="item in payMentModeList" :key="item.value" :label="item.label" :value="item.value">
				  	</el-option>
				  </el-select>
					<el-tag class="formOrderDetail" type="info">{{transferOrderDetail.payMentMode}}</el-tag>
				</el-form-item>
				<el-form-item v-if="transferInfo.payMentMode == 'COD'" :label="$t('commonInfo.amountToCollect')" prop="amountToCollect">
				  <el-input v-model="transferInfo.amountToCollect" style="width: 300px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.transfer') + $t('commonInfo.amountToCollect')" />
					<el-tag class="formOrderDetail" type="info">{{transferOrderDetail.amountToCollect}}</el-tag>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.contact')" prop="deliveryContact">
				  <el-input v-model="transferInfo.deliveryContact" style="width: 300px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.transfer') + $t('commonInfo.contact')" />
					<el-tag class="formOrderDetail" type="info">{{transferOrderDetail.deliveryContact}}</el-tag>
				</el-form-item>
				<el-form-item :label="$t('commonInfo.deliveryAddress')" prop="deliveryAddress">
				  <el-input type="textarea" v-model="transferInfo.deliveryAddress" style="width: 300px;" :placeholder="$t('tipsInfo.inputPlaceholder') + $t('commonInfo.transfer') + $t('commonInfo.deliveryAddress')" />
					<el-tag style="white-space: normal; height: auto;line-height: 26px;" class="formOrderDetail" type="info">{{transferOrderDetail.deliveryAddress}}</el-tag>
				</el-form-item>
			</el-form>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="handleDialogClose" :disabled="confirmLoading">{{$t('commonInfo.dialogBtnCancel')}}</el-button>
		    <el-button type="primary" :disabled="confirmLoading" :loading="confirmLoading" @click="submitTransfer()">{{confirmLoading?$t('commonInfo.dialogBtnSubmiting'):$t('commonInfo.dialogBtnSubmit')}}</el-button>
		  </span>
		</el-dialog>
		<!-- 导入Excel后错误的对话框 -->
		<el-dialog :lang="$i18n.locale" title="Excel Data" :visible.sync="dialogErrorVisible" width="640px" :close-on-click-modal="false">
		  <el-table :data="returnSkuData">
				<el-table-column property="orderNo" :label="$t('commonInfo.orderNoColumn')" align="center"></el-table-column>
		    <el-table-column property="productNm" :label="$t('commonInfo.productName')" align="center"></el-table-column>
		    <el-table-column property="companyProductCode" :label="$t('commonInfo.clientProductCode')" align="center"></el-table-column>
				<el-table-column property="count" :label="$t('commonInfo.clientProductCode')" align="center"></el-table-column>
				<el-table-column property="errorMsg" :label="$t('commonInfo.result')" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.count != null && scope.row.errorMsg == null" style="color: #0086B3;">{{$t('tipsInfo.success')}}</span>
						<span v-if="scope.row.count == null && scope.row.errorMsg != null" style="color: red;">{{scope.row.errorMsg}}</span>
					</template>
				</el-table-column>
		  </el-table>
		</el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
		formatDate
	} from "@/utils/index";
import {validNumberToFix2} from '@/utils/validate' //验证数字
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
			activeName:'first',
      queryParams: {
        page: 1,
        limit: 10,
				delFlag:0,// 0未操作操作中数据 1已完成任务
				dlvdAwbNos:[],
      },
			headers: {},
			dlvdAwbNosArr:'',
			loading:false,
			uploadloading:false,
			confirmLoading:false,
      total: 0,
			dataList:[],
			//产品上架窗口
			dialogErrorVisible:false,
			submitLoading:false,
			skuPopup:false,
			skuOrderNo:[],
			skuModelUrl:'',
			transferModelUrl:'',
			toSkuPreFileList:[],
			toSkuLink:'',
			returnSkuData:[],
			isSuccessData:false,
			
			// 转单小包窗口
			transferPopup:false,
			transferOrderDetail:{},
			transferInfo:{
				consignee:'', // 收件人名称
				destCity:'',// 目的城市
				deliveryAddress:'',//收件地址
				deliveryContact:'', //  收件人电话
				deliveryPincode:'', // 收件邮编 
				// multipleTransferAwbNo:'',//批量转单批次号
				payMentMode:'',//支付方式类型 COD 、 PPD
				amountToCollect:'',//支付金额，只有COD类型需要
			},
			payMentModeList:[
				{
					label:'COD',
					value:'COD'
				},
				{
					label:'PPD',
					value:'PPD'
				}
			],
			
			transferType:1,//1，单个转单，2，批量转单
			selectionRow:[],//勾选行
			ids:[],//翻页勾选记录
			// 第二个tab 已完成列表
			alreadyQuery:{
				page: 1,
				limit: 10,
				delFlag:1,// 0未操作操作中数据 1已完成任务
				dlvdAwbNos:[],
			},
			alTotal:0,
			alAwbNoArr:'',
			alLoading:false,
			alDataList:[],
			
			
			customerName:'',
    };
  },
  //监听属性 类似于data概念
  computed: {
		rules() {// 表单验证需要在计算属性定义，才能实现实时更新国际化提示信息
		  return {
				consignee: [{
					required: true,
					message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.transfer') + this.$t('commonInfo.consignee'),
					trigger: 'blur'
				}],
				destCity: [{
					required: true,
					message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.transfer') + this.$t('commonInfo.city'),
					trigger: 'blur'
				}],
				deliveryAddress: [{
					required: true,
					message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.transfer') + this.$t('commonInfo.deliveryAddress'),
					trigger: 'blur'
				}],
				deliveryContact:[{
					required: true,
					message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.transfer') + this.$t('commonInfo.contact'),
					trigger: 'blur'
				}],
				payMentMode:[{
					required: true,
					message: this.$t('tipsInfo.selectPlaceholder') + this.$t('commonInfo.transfer') + this.$t('commonInfo.payMentMode'),
					trigger: 'blur'
				}],
				amountToCollect:[{
					required: true,
					message: this.$t('tipsInfo.inputPlaceholder') + this.$t('commonInfo.transfer') + this.$t('commonInfo.amountToCollect'),
					trigger: 'blur'
				},{ validator: validNumberToFix2, trigger: 'blur'}]
				// multipleTransferAwbNo:[{
				// 	required: true,
				// 	message: "请输入转单批次",
				// 	trigger: 'blur'
				// }]
			}
		}
	},
  //监控data中的数据变化
  watch: {
	},
  //方法集合
  methods: {
		// 分页条数
		handleSizeChange(size) {
		  this.queryParams.limit = size;
		  this.queryParams.page = 1;
		  this.getList();
		},
		// 分页页数
		handleCurrentChange(page) {
		  this.queryParams.page = page;
		  this.getList();
		},
		
		// 已完成分页条数
		handleAlSizeChange(size) {
		  this.alreadyQuery.limit = size;
		  this.alreadyQuery.page = 1;
		  this.getAlList();
		},
		// 已完成分页页数
		handleAlCurrentChange(page) {
		  this.alreadyQuery.page = page;
		  this.getAlList();
		},
		
		handleClick(tab, event){
		  if (tab.name == "first") {
		  	this.getList();
		  }else if(tab.name == "second"){
		    this.getAlList();
		  }
		},
    async getList() {
			this.loading = true;
			this.dataList = [];
			const res = await this.$http.post("/toborder/customer/toc/rto/list", this.queryParams);
			
			if (res.code === 200) {
			  let data = res.data.list;
			  // 总共有多少条数据，分页
			  this.total = res.data.totalCount;
				//去除勾选
				this.ids = [];
				this.selectionRow = [];
				this.$refs.tableList.clearSelection();
				data.forEach(item => {
					item.initTime = formatDate(item.initTime);
					// if(item.actionTypeStatusName == '' || item.actionTypeStatusName == null){
					// 	item.actionTypeStatusName = '暂无'
					// }
				})
				
			  this.loading = false;
			  this.dataList = data;
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		handleQuery(){
			this.queryParams.page = 1;
			if (this.dlvdAwbNosArr != "") {
				this.queryParams.dlvdAwbNos = this.strToArr(this.dlvdAwbNosArr);
			} else {
			  this.queryParams.dlvdAwbNos = [];
			}
			this.getList();
		},
		handleRefresh(){
			this.queryParams = {
				page:1,
				limit:10,
				delFlag:0,// 0未操作操作中数据 1已完成任务
				dlvdAwbNos:[],
			}
			this.getList();
		},
		// 获取存储时间 根据当前时间和创建时间获取 2022-5-27
		getStoreDay(row){
			let differTime = '';
			// if(row.actionResult == null || row.actionResult == ''){// 未操作
			// 	let nowTime = new Date();
			// 	let startTime = new Date(row.initTime);
			// 	differTime = parseInt((nowTime.getTime() - startTime.getTime()) / (1000*60*60*24));
			// 	// 显示倒计时
			// 	differTime = 30 - differTime;
			// }
			if(row.days == '' || row.days == null){
				differTime = 0;
			}else{
				let storeDayString = row.days.split('天');
				differTime = parseInt(storeDayString[0]);
				// 如果剩下几小时，则默认一天
				differTime = differTime == 0?1:differTime;
			}
			return differTime;
		},
		// 获取订单状态
		returnActionStatus(row,type){
			let statusName = '',statusIcon="";
			if(row.actionResult == '' || row.actionResult == null){
				statusName = this.$t('commonInfo.waitForOrder');
				statusIcon = '';
			}else if(row.actionResult == '100'){
				switch(row.actionTypeStatus){
					case '1':statusName = this.$t('commonInfo.destory') + this.$t('commonInfo.processing');break;
					case '2':statusName = this.$t('commonInfo.transfer') + this.$t('commonInfo.processing');break;
					case '3':statusName = this.$t('commonInfo.skuStock') + this.$t('commonInfo.processing');break;
				}
				statusIcon = 'el-icon-loading';
			}else if(row.actionResult == '300'){
				switch(row.actionTypeStatus){
					case '1':statusName = this.$t('commonInfo.destory') + this.$t('commonInfo.completed');break;
					case '2':statusName = this.$t('commonInfo.transfer') + this.$t('commonInfo.completed');break;
					case '3':statusName = this.$t('commonInfo.skuStock') + this.$t('commonInfo.completed');break;
				}
				statusIcon = 'el-icon-finished';
			}
			if(type == 1){
				return statusName
			}else{
				return statusIcon
			}
		},
		// 获取唯一标识
		getRowKey(row){
		  return row.id;
		},
		//监听勾选
		changeSelection(selection) {
			this.ids = selection.map(item => item.id);
		  this.selectionRow = selection;
		},
		//销毁
		handleDestory(orderNo){
			this.skuOrderNo = [orderNo];
			this.$confirm(this.$t('rtoB2COrderPage.confirmDestoryOrder'), this.$t('tipsInfo.warning'), {
					confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
					cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
					type: "warning"
				})
				.then(() => {
					this.confirmToSku('1');
				})
				.catch(() => {});
		},
		// 转单小包
		handleTransfer(row){
			// 获取订单详情
			this.orderDetail(row.id);
			this.transferPopup = true;
			this.transferType = 1;
			this.skuOrderNo = [row.awbNo];
		},
		// 订单详情
		async orderDetail(id) {
			const res = await this.$http.post("toborder/customer/toc/rto/getTransferOrderInfo/" + id);
			if (res.code == 200) {
				this.transferOrderDetail = res.data;
				this.transferInfo = JSON.parse(JSON.stringify(res.data));
			} else {
				this.$message.error(res.msg);
			}
		},
		// 批量转单
		// handleMultipleTransfer(){
		// 	this.transferPopup = true;
		// 	this.transferType = 2;
		// },
		handleMultipleDestory(){
			if(this.selectionRow.length == 0){
				return this.$message.warning(this.$t('tipsInfo.selectOrderTips'))
			}
			this.skuOrderNo = this.selectionRow.map(item => item.awbNo);
			this.$confirm(this.$t('rtoB2COrderPage.confirmMultipleDestoryOrder'), this.$t('tipsInfo.warning'), {
					confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
					cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
					type: "warning"
				})
				.then(() => {
					this.confirmToSku('1');
				})
				.catch(() => {});
		},
		// 提交
		submitTransfer(){
			this.$refs["transferForm"].validate(valid => {
				if(valid){
					this.$confirm(this.$t('rtoB2COrderPage.confirmToTransferOrder'), this.$t('tipsInfo.warning'), {
							confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
							cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
							type: "warning"
						})
						.then(() => {
							this.confirmToSku('2');
						})
						.catch(() => {});
				}
			})
		},
		// 转产品上架
		handleCreateSku(){
			this.skuPopup = true;
		},
		
		// 产品上架
		handleInventorySKU(type,awbNo){// type 1 单个 2、批量
		  let arr = [];
			if(type == '2'){
				if(this.selectionRow.length == 0){
					return this.$message.warning(this.$t('tipsInfo.selectOrderTips'))
				}
				this.skuOrderNo = this.selectionRow.map(item => item.awbNo);
			}else{
				this.skuOrderNo = [awbNo];
			}
			this.$confirm(this.$t('rtoB2COrderPage.confirmInventoryOrder'), this.$t('tipsInfo.warning'), {
					confirmButtonText: this.$t('commonInfo.dialogBtnConfirm'),
					cancelButtonText: this.$t('commonInfo.dialogBtnCancel'),
					type: "warning"
				})
				.then(() => {
					this.confirmToSku('3');
				})
				.catch(() => {});
		},
		
		handleDialogClose(){
			this.skuPopup = false;
			this.isSuccessData = false;
			this.returnSkuData = [];
			this.toSkuPreFileList = [];
			
			this.transferPopup = false;
			this.transferOrderDetail = {};
			this.transferInfo = {
				consignee:'', // 收件人名称
				destCity:'',// 目的城市
				deliveryAddress:'',//收件地址
				deliveryContact:'', //  收件人电话
				deliveryPincode:'', // 收件邮编 
				// multipleTransferAwbNo:'',//批量转单批次号
				payMentMode:'',//支付方式类型 COD 、 PPD
				amountToCollect:'',//支付金额，只有COD类型需要
			}
			this.$refs.transferForm.clearValidate();
		},
		async getModelUrl(){
			const res = await this.$http.get("/toborder/system/dict/data/list",{
			  params: {
					page:1,
					limit:10,
					dictType:'sys_template_url'
				}
			});
			
			if (res.code === 200) {
				let that = this;
				let data = res.rows;
				data.forEach(item => {
					if(item.dictLabel == 'RTO B2C产品上架模板'){
						that.skuModelUrl = item.remark;
					}
					if(item.dictLabel == 'RTO B2C批量转单模板'){
						that.transferModelUrl = item.remark;
					}
				})
			} else {
			  this.$message.error(res.msg);
			}
		},
		// 下载转单模板
		handleDownloadTransferUrl(){
			window.open(this.transferModelUrl);
		},
		// 下载清单模板
		handleDownloadUrl(){
			window.open(this.skuModelUrl);
		},
		async confirmToSku(type){
			let productList = [],skuParams = {};
			if(type == '2'){//转单小包
				skuParams.consignee = this.transferInfo.consignee;
				skuParams.deliveryAddress = this.transferInfo.deliveryAddress;
				skuParams.deliveryContact = this.transferInfo.deliveryContact;
				skuParams.deliveryPincode = this.transferInfo.deliveryPincode;
				skuParams.destCity = this.transferInfo.destCity;
				skuParams.payMentMode = this.transferInfo.payMentMode;
				if(this.transferInfo.payMentMode == "COD"){
					skuParams.amountToCollect = this.transferInfo.amountToCollect;
				}
			}
			// if(type == '3'){//产品上架需要上传SKU信息
			//   this.skuOrderNo = [];
			// 	this.returnSkuData.forEach(item => {
			// 		productList.push({
			// 			sku:item.companyProductCode,  //sku
			// 			count:item.count,    //数量
			// 			orderNo:item.orderNo, // 订单号
			// 		})
			// 		this.skuOrderNo.push(item.orderNo);
			// 	})	
			// 	skuParams.skuList = productList;
			// 	skuParams.skuListLink = this.toSkuLink;
			// }
			skuParams.awbNo = this.skuOrderNo;
			skuParams.actionTypeStatus = type;//1,销毁，2,转单小包，3，产品上架
			this.confirmLoading = true;
			const res = await this.$http.post("/toborder/customer/toc/rto/operation",skuParams);
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.confirmLoading = false;
				if(type == '2'){
					this.handleDialogClose();
				}
				this.getList();
			} else {
				this.confirmLoading = false;
			  this.$message.error(res.msg);
			}
		},
		
		// 第二个tab页 显示已完成列表 2022-5-31 zpy
		// 搜索
		handleAlQuery(){
			this.alreadyQuery.page = 1;
			if (this.alAwbNoArr != "") {
				this.alreadyQuery.dlvdAwbNos = this.strToArr(this.alAwbNoArr);
			} else {
			  this.alreadyQuery.dlvdAwbNos = [];
			}
			this.getAlList();
		},
		// 获取列表
		async getAlList(){
			this.alLoading = true;
			this.alDataList = [];
			const res = await this.$http.post("/toborder/customer/toc/rto/list", this.alreadyQuery);
			
			if (res.code === 200) {
			  let data = res.data.list;
			  // 总共有多少条数据，分页
			  this.alTotal = res.data.totalCount;
			  this.alLoading = false;
				data.forEach(item => {
					item.initTime = formatDate(item.initTime);
				})
			  this.alDataList = data;
			} else {
			  this.alLoading = false;
			  this.$message.error(res.msg);
			}
		},
		
		//上传成功
		handlesuccess(files, fileList) {
			this.uploadloading = false;
		  if (files.code == 200) {
		    if (files.data.success) {
		      this.$message.success(this.$t('tipsInfo.uploadSuccess'));
					this.toSkuPreFileList = [{
						name:this.$t('commonInfo.actionCheckFile'),
						url: files.data.skuListLink,
					}];
					this.toSkuLink = files.data.skuListLink;
					//上传成功显示列表
					this.returnSkuData = files.data.productErrorInfoList;
					this.isSuccessData = true;
					this.dialogErrorVisible = true;
					
		    } else if (files.data.success == false) {
					this.toSkuPreFileList = [];
					this.toSkuLink = '';
					this.isSuccessData = false;
		      this.returnSkuData = files.data.productErrorInfoList;
		      this.dialogErrorVisible = true;
		    }
		  } else if (files.code == 500) {
				this.isSuccessData = false;
				this.toSkuPreFileList = [];
				this.toSkuLink = '';
		    this.$message.error(files.msg);
		  }
		  // console.log(files);
		
		  if (files.code == 401) {
		    this.$message.error(this.$t('tipsInfo.loginOutTips'));
		    window.sessionStorage.removeItem("token");
		    return this.$router.push({
		      path: "/login"
		    });
		  }
		  // console.log(files);
		},
		// 批量转单成功
		handlesuccessMutipleTransfer(files, fileList){
			this.uploadloading = false;
			if(files.code == 200){
				this.$message.success(this.$t('tipsInfo.uploadSuccess'));
				this.getList();
			}else{
				this.$message.error(files.msg);
			}
		},
		// 上传时触发
		beforeUpload(file) {
			let fileNameStr = file.name;
			if(fileNameStr.length > 30){
				return this.$message.warning(this.$t('tipsInfo.uploadFileNameLimitTips'));
			}
		  var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
		  const extension = testmsg === "xls";
		  const extension2 = testmsg === "xlsx";
		  if (!extension && !extension2) {
		    this.$message.warning(
		      this.$t('tipsInfo.uploadExcelTypeTips')
		    );
		  }
		  return extension || extension2;
		},
		// 上传失败
		handleError(err, file, fileList) {
			this.uploadloading = false;
		},//点击预览
		handlePreview(file) {
			console.log(file);
			window.open(file.url, "_blank");
		},
		// 上传时
		handleProgress(event, file, fileList) {
			this.uploadloading = true;
		  // this.$message.warning("Uploading, please hold on");
		},
  },
  created() {
		let token = window.sessionStorage.getItem("token");
		let userInfo = JSON.parse(window.sessionStorage.getItem("params"));
		this.customerName = userInfo.username;
		this.headers = {
			Authorization: token,
		}
		this.getModelUrl();//获取上传SKU模板地址
		this.getList();
	},
  mounted() {}
};
</script>
<style scoped>
	.app-container{
		padding: 10px;
	}
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px;
	}
	.orderStatus{
		padding: 10px 15px;
		border-radius: 5px;
		display: inline-block;
		box-sizing: border-box;
		line-height: 1;
		font-size: 14px;
	}
	.transferRow{
		padding-left: 70px;
		margin: 15px 0;
		font-size: 16px;
	}
	.formOrderDetail{
		width: 300px;
		display: inline-block;
		margin: 0px 10px;
		position: absolute;
	}
	.awbNoRow{
		color: #006895;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
	}
</style>